<template>
    <MobileAnnounceContainer v-if="isMobile"  :contentIntro="contentIntro" :announceTime="announceTime"></MobileAnnounceContainer>
	<div class="announcement" v-else>
		<crumbs :item="title"></crumbs>
		<div class="wrap">
			<div class="box" v-for="(item, index) in contentIntro" :key="index">
				<!-- <p>{{ item.title }}</p> -->
				<p v-html="item.title" v-show=" (item.code!='AYTL51' && item.code!='GUVL0548' && item.code!='AYTL52')|| isShow"></p>
				<table
					border="1px solid #ccc"
					cellspacing="0"
					cellpadding="0"
					class="table_style"
					v-show=" (item.code!='AYTL51' && item.code!='GUVL0548' && item.code!='AYTL52') || isShow"
				>
					<tbody>
						<tr>
							<td colspan="5">◎每月宣告利率</td>
						</tr>
						<tr>
							<td colspan="5">
								<span style="color: #003781">公布日:&nbsp;&nbsp;</span
								><span>{{ announceTime }} </span>
							</td>
						</tr>
						<tr>
							<td></td>
							<td>最低保证年利率</td>
							<td>月初浮动年利率</td>
							<td>月结算年利率</td>
							<td>月结算日利率</td>
						</tr>
						<tr v-for="(rateitem, rateindex) in item.rate" :key="rateindex">
							<td>{{ rateitem.TERM }}</td>
							<td>{{ rateitem.GRNT_RATE }}</td>
							<td>{{ rateitem.FLOAT_RATE }}</td>
							<td>{{ rateitem.INV_RATE }}</td>
							<td>{{ rateitem.DAY_RATE }}</td>
						</tr>
						<!-- ◎保单持续奖金 -->
						<tr
							v-if="
								item.code == 'AYTL43' ||
								item.code == 'SSWY43' ||
								item.code == 'AYHL44' ||
								item.code == 'AYHL43' ||
								item.code == 'AYTL51' ||
								item.code == 'AYTL52' 

							"
						>
							<td colspan="2">◎保单持续奖金</td>
							<td colspan="3">{{ item.contPolicy }}</td>
						</tr>
						<!-- ◎持续奖金率 -->
						<tr
							v-if="
								item.code == 'YYLC28' ||
								item.code == 'JYSJ13' ||
								item.code == 'FYRS13' ||
								item.code == 'LHLC13' ||
								item.code == 'DYJ13'
							"
						>
							<td colspan="2">◎持续奖金率</td>
							<td colspan="3">{{ item.contPolicyRate }}</td>
						</tr>
						<!-- ◎当时市场利率 -->
						<tr
							v-if="
								item.code == 'YYLC28' ||
								item.code == 'JYSJ13' ||
								item.code == 'FYRS13' ||
								item.code == 'LHLC13' ||
								item.code == 'DYJ13'
							"
						>
							<td colspan="2">◎当时市场利率</td>
							<td colspan="3">{{ item.currentRate }}</td>
						</tr>
						<tr>
							<td
								colspan="5"
								style="text-align: left; padding: 10px"
								v-html="item.text"
							></td>
						</tr>
					</tbody>
				</table>
				<div class="historyBtn" @click="history(item.code)"  v-show=" (item.code!='AYTL51' && item.code!='GUVL0548' && item.code!='AYTL52')|| isShow">历史利率查询</div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from "@/components/crumbs.vue";
	import { getNewAnnouce, getNewPerstst } from "@/api/newsnew/announcement.js";
    import $ from "jquery";
	import common from '@/utils/common'
    import MobileAnnounceContainer from './mobile/announcementMobile.vue'
	const contentIntro = [
		{
			code: "AYTL43",
			accId: "43",
			contPolicy: "1%", //@◎保单持续奖金
			title: "安联安盈添利终身寿险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
			rate: [],
		},
		{
			code: "SSWY43",
			accId: "43",
			contPolicy: "3%", //@◎保单持续奖金
			title: "安联盛世稳盈年金保险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至本合同约定的满期日且本合同仍然有效，我们在满期日按照当时趸交保险费余额的 3%发放持续奖金。",
			rate: [],
		},
		{
			code: "AYWL42",
			accId: "42",
			title: "安联安盈稳利两全保险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "YYLC28",
			accId: "28",
			contPolicyRate: "0", //@◎持续奖金率
			currentRate: "3%", //@◎当前市场利率
			title: "安联优越理财终身寿险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用。",
			rate: [],
		},
		{
			code: "AYHL44",
			accId: "44",
			contPolicy: "3%", //@◎保单持续奖金
			title: "安联安盈恒利两全保险（万能型）（2021年9月1日后生效的保单）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至本合同约定的满期日且本合同仍然有效，我们在满期日按照当时趸交保险费余额的 3%发放持续奖金。",
			rate: [],
		},
		{
			code: "AYHL43",
			accId: "43",
			contPolicy: "3%", //@◎保单持续奖金
			title: "安联安盈恒利两全保险（万能型）（2021年9月1日前生效的保单）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至本合同约定的满期日且本合同仍然有效，我们在满期日按照当时趸交保险费余额的 3%发放持续奖金。",
			rate: [],
		},
		{
			code: "JYSJ13",
			accId: "13",
			title: "联众金赢世家终身寿险（万能型）",
			contPolicyRate: "0", //@◎持续奖金率
			currentRate: "3%", //@◎当前市场利率
			text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用；<br/>持续奖金分别于第4、6、8、10个保单年度末根据当时保单帐户价值计算。",
			rate: [],
		},
		{
			code: "FYRS13",
			accId: "13",
			title: "联众福佑人生终身寿险（万能型）",
			contPolicyRate: "0", //@◎持续奖金率
			currentRate: "3%", //@◎当前市场利率
			text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用；<br/>原有三年期帐户于2008年3月5日起更名为“特A十年期理财帐户”,使用十年期帐户宣告利率；<br/>原有七年期帐户于2008年3月5日起更名为“特B十年期理财帐户”,使用十年期帐户宣告利率。",
			rate: [],
		},
		{
			code: "LHLC13",
			accId: "13",
			title: "联众灵活理财终身寿险（万能型）",
			contPolicyRate: "0", //@◎持续奖金率
			currentRate: "3%", //@◎当前市场利率
			text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用；<br/>原有三年期帐户于2008年3月5日起更名为“特A十年期理财帐户”,使用十年期帐户宣告利率；<br/>原有七年期帐户于2008年3月5日起更名为“特B十年期理财帐户”,使用十年期帐户宣告利率。",
			rate: [],
		},
		{
			code: "GUVL0113",
			accId: "13",
			title: "联众团体年金保险（万能型）（2005）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "GUVL0213",
			accId: "13",
			title: "安联团体年金保险（万能型）（2007）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "GUVL0313",
			accId: "13",
			title: "安联团体年金保险（万能型）（2008）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "GUVL0445",
			accId: "45",
			title: "安联团体（2023）年金保险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "DYJ13",
			accId: "13",
			contPolicyRate: "0", //@◎持续奖金率
			currentRate: "3%", //@◎当前市场利率
			title: "联众大赢家终身寿险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "GUVL0548",
			accId: "48",
			title: "安联团体（2024）年金保险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
			rate: [],
		},
		{
			code: "AYTL51",
			accId: "51",
			contPolicy: "1%", //@◎保单持续奖金
			title: "安联安盈添利（II）终身寿险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
			rate: [],
		},
		{
			code: "AYTL52",
			accId: "52",
			contPolicy: "1%", //@◎保单持续奖金
			title: "安联安盈添利A（II）终身寿险（万能型）",
			text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
			rate: [],
		},
	];
	export default {
		name: "announcement",
		data() {
			return {
                isMobile: false,
				isShow:false,
				title: [
					{
						title: "新闻公告",
						link: "",
					},
					{
						title: "万能险利率公告",
						link: "/announcement",
					},
				],
				url: "https://www.allianz.com.cn/ProductValueQuery/faces/rateNotice.jspx?inv_acc_id=43&cover=AYTL",
				contentIntro, //每个tab的数据
				announceTime: "", //公布日
			};
		},
		components: {
			crumbs,
            MobileAnnounceContainer,
		},
		methods: {
			//跳转到详细页面
			history(covercode) {
				this.$router.push({
					name: "interestrateHistory",
					params: {
						countType: covercode,
					},
				});
				localStorage.setItem("interestrateHistoryCovercode", covercode);
			},

			//接口
			async getNewAnnouceAll() {
				const [timeStamp, sign] = this.$getSign();

				//contPolicyRate持续奖金率
				const {
					data: {
						data: { PERSIST },
					},
				} = await getNewPerstst({ timeStamp, sign });
				this.contentIntro.map((item) => {
					if (item.contPolicyRate) {
						item.contPolicyRate = `${PERSIST}%`;
					}
				});

				//5年 10年的利率值
				const promises = ["43", "42", "28", "44", "13", "45","48","51","52"].map((accId) =>
					getNewAnnouce({ timeStamp, sign, accId })
				);
				const results = await Promise.all(promises);
				const [announce43, announce42, announce28, announce44, announce13, announce45,announce48,announce51,announce52] =
					results;

				this.contentIntro.map((item) => {
					//@安盈添利终身寿险（万能型）
					if (item.code == "AYTL43") {
						const { RELEASE_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce43.data.rows[1];
						this.announceTime = RELEASE_DATE.substring(0, 10); //@统一修改公布日时间
						item.rate.push({
							//表格数据
							TERM: ``,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						//@盛世稳盈年金保险（万能型）     安盈恒利两全保险（万能型）（2021年9月1日前生效的保单）
					} else if (item.code == "SSWY43" || item.code == "AYHL43") {
						const { TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce43.data.rows[1];
						item.rate.push({
							//表格数据
							TERM: `${TERM}年`,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						//@安盈稳利两全保险（万能型）
					} else if (item.code == "AYWL42") {
						const { TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce42.data.rows[1];
						item.rate.push({
							//表格数据
							TERM: `${TERM}年`,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						//@优越理财终身寿险（万能型）
					} else if (item.code == "YYLC28") {
						const { TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce28.data.rows[0];
						item.rate.push({
							//表格数据
							TERM: `${TERM}年`,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						//@安盈恒利两全保险（万能型）（2021年9月1日后生效的保单）
					} else if (item.code == "AYHL44") {
						const { TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce44.data.rows[1];
						item.rate.push({
							//表格数据
							TERM: `${TERM}年`,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						//@金赢世家终身寿险（万能型）   联众团体年金保险（万能型）（2005）   安联团体年金保险（万能型）（2007）   安联团体年金保险（万能型）（2008）   联众大赢家终身寿险（万能型）
					} else if (
						item.code == "JYSJ13" ||
						item.code == "GUVL0113" ||
						item.code == "GUVL0213" ||
						item.code == "GUVL0313" ||
						item.code == "DYJ13"
					) {
						const { TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce13.data.rows[1];
						item.rate.push({
							//表格数据
							TERM: `${TERM}年`,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						//@福佑人生终身寿险（万能型）  灵活理财终身寿险（万能型）
					} else if (item.code == "LHLC13" || item.code == "FYRS13") {
						announce13.data.rows.map((itemdetail) => {
							const { TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
								itemdetail;
							item.rate.push({
								//表格数据
								TERM: `${TERM}年`,
								GRNT_RATE: `${GRNT_RATE}%`,
								FLOAT_RATE: `${FLOAT_RATE}%`,
								INV_RATE: `${INV_RATE}%`,
								DAY_RATE: `${DAY_RATE}%`,
							});
						});
					}
					//@安联团体（2023）年金保险（万能型）     
					else if (item.code == "GUVL0445") {
						const { RELEASE_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
							announce45.data.rows[1];
						this.announceTime = RELEASE_DATE.substring(0, 10); //@统一修改公布日时间
						item.rate.push({
							//表格数据
							TERM: ``,
							GRNT_RATE: `${GRNT_RATE}%`,
							FLOAT_RATE: `${FLOAT_RATE}%`,
							INV_RATE: `${INV_RATE}%`,
							DAY_RATE: `${DAY_RATE}%`,
						});

						
					}else if (item.code == "GUVL0548") {
						console.log('==============',announce48.data.rows)
						announce48.data.rows.forEach(element => {
							if(element.INV_ACC_ID == '48'){
								const { RELEASE_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE }=element;
								this.announceTime = RELEASE_DATE.substring(0, 10); //@统一修改公布日时间
								item.rate.push({
									//表格数据
									TERM: ``,
									GRNT_RATE: `${GRNT_RATE}%`,
									FLOAT_RATE: `${FLOAT_RATE}%`,
									INV_RATE: `${INV_RATE}%`,
									DAY_RATE: `${DAY_RATE}%`,
								});
							}
						}); 
					}else if (item.code == "AYTL51") {
						announce51.data.rows.forEach(element => {
							if(element.INV_ACC_ID == '51'){
								const { RELEASE_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =element
								this.announceTime = RELEASE_DATE.substring(0, 10); //@统一修改公布日时间
								item.rate.push({
									//表格数据
									TERM: ``,
									GRNT_RATE: `${GRNT_RATE}%`,
									FLOAT_RATE: `${FLOAT_RATE}%`,
									INV_RATE: `${INV_RATE}%`,
									DAY_RATE: `${DAY_RATE}%`,
								});
							}
						})
					}
					else if (item.code == "AYTL52") {
						announce52.data.rows.forEach(element => {
							if(element.INV_ACC_ID == '52'){
								const { RELEASE_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =element
								this.announceTime = RELEASE_DATE.substring(0, 10); //@统一修改公布日时间
								item.rate.push({
									//表格数据
									TERM: ``,
									GRNT_RATE: `${GRNT_RATE}%`,
									FLOAT_RATE: `${FLOAT_RATE}%`,
									INV_RATE: `${INV_RATE}%`,
									DAY_RATE: `${DAY_RATE}%`,
								});
							}
						})
					}
				});
			},
		},
		created() {
            this.isMobile = common.isMobile();
            var isPad = common.isPad();
			if(isPad){
				console.log(isPad);
				this.isMobile = false
			}
			console.log(isPad);
            console.log(this.isMobile);
            if (this.isMobile) {
                $("body").css("overflow", "hidden");
            }
			this.getNewAnnouceAll(); //获取所有的表格
			//暂定在 2024年的10月1日之后显示
			let nowDate=new Date().getTime();
			if(nowDate>=1727712000000){
				this.isShow=true
			}
		},
	};
</script>

<style lang="less" scoped>
	.announcement {
		.wrap {
			width: 100%;

			.box {
				width: 55%;
				margin: auto;
				padding: 2rem 0;

				//height: 80vw;
				//border: 1px solid;
				p {
					font-size: 2.5rem;
					color: #003781;
					// width: 70%;
					margin: 0rem auto 2rem;
					text-align: center;
				}

				.iframe {
					//width: 70%;
					width: 600px;
					margin: 0 auto 0rem;
					//height: 25vw;
					/* border: 1px solid; */
					display: flex;
					justify-content: center;

					/deep/ .x19 {
						margin: auto;
					}

					/deep/ .xdk {
						text-align: center !important;
					}
				}
			}
		}
	}

	::v-deep .x19 {
		margin: auto;
	}

	::v-deep .xhh {
		width: 580px;
		display: block;
		margin: auto;
	}

	.table_style {
		border-collapse: collapse;
		width: 100%;

		tr {
			height: 3.5rem;

			td {
				font-family: 方正正中黑简体;
				color: #3e3e3e;
				font-size: 1.8rem;
				width: 20%;
				text-align: center;
				border: 1px solid #3e3e3e;
				font-weight: 400;
			}
		}
	}

	.historyBtn {
		font-size: 2rem;
		color: #72007c;
		padding-top: 2rem;
		width: 80%;
		cursor: pointer;
	}
</style>
